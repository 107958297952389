import { DatePicker, Item, Select, TextInput, Text, Checkbox, useNotifications } from "@ilc-technology/luik";
import { LabelKey } from "../../Common/StoryblokTypes";
import IntlTelInput from "intl-tel-input/react";
import { Form, Formik, FormikValues } from "formik";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import React, { useEffect, useState } from "react";
import { TRUE } from "../../Common/Constants";
import { nameofFactory } from "../../Common/Helpers/TextHelper";
import { getCountryName } from "../../Common/services/Countries";
import { useSessionContext } from "../../contexts/SessionContext/SessionContext";
import moment from "moment/moment";
import * as Yup from "yup";
import { CustomTrackingEvent, trackEvent } from "../../Common/services/Analytics";
import { MultiStepFormStepProps } from "./MultiStepForm";
import { convertToErrorDetails, logError } from "../../Common/services/ErrorService";
import { ErrorCode, ErrorDetails } from "../../Common/Types";
import { CustomerServiceApi } from "../../apis/customerServiceApi";
import ErrorComponentWrapper from "../ErrorHandling/ErrorComponentWrapper";
import { RequestCustomerChange } from "../../apis/generatedCustomerServiceApiClient";

export interface StudentBasicDetailsForm {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  isInvoiceEmailSelected: boolean;
  invoiceEmail: string;
  dateOfBirth: string;
  birthCity: string;
  birthCountryCode: string;
  mobilePhone: string;
  nationalityCountryCode: string;
  passportNumber: string;
  gender: string;
  firstNameLatin: string;
  middleNameLatin: string;
  lastNameLatin: string;
  street: string;
  city: string;
  postalCode: string;
  country: string;
  nationalId: string;
}

const StudentBasicDetails: React.FC<MultiStepFormStepProps> = ({
  onFormValuesChange,
  onNext,
  complete,
  onError,
  quote,
}) => {
  const { session, language } = useSessionContext();
  const { labels, countries, nationalities, featureSettings } = useDatasources();
  const [isPhoneNumberValid, setPhoneNumberValid] = useState(true);
  const nameofStudentBasicDetailsFrom = nameofFactory<StudentBasicDetailsForm>();
  const customerServiceApi = new CustomerServiceApi();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<ErrorDetails | undefined>(undefined);
  const notifications = useNotifications();
  const [initialStudentBasicDetails, setInitialStudentBasicDetails] = useState<StudentBasicDetailsForm | undefined>(
    undefined
  );

  const getCustomerInfo = () => {
    setIsLoading(true);
    customerServiceApi
      .getCustomer(session.accountUuid)
      .then(async (result) => {
        const basicDetails = {
          firstName: result.firstName ?? "",
          middleName: result.middleName ?? "",
          lastName: result.lastName ?? "",
          email: result.personDetails?.email ?? "",
          isInvoiceEmailSelected: !!result.personDetails?.invoiceEmail,
          invoiceEmail: result.personDetails?.invoiceEmail ?? "",
          dateOfBirth: result.personDetails?.dateOfBirth
            ? moment(result.personDetails?.dateOfBirth).format("YYYY-MM-DD")
            : "",
          birthCity: result.personDetails?.birthCity ?? "",
          mobilePhone: result.personDetails?.mobilePhone ?? "",
          nationalityCountryCode: result.personDetails?.nationalityCountryCode ?? "",
          birthCountryCode: result.personDetails?.birthCountryCode ?? "",
          passportNumber: result.personDetails?.passportNumber ?? "",
          nationalId: result.personDetails?.nationalId ?? "",
          gender: result.personDetails?.gender ?? "",
          firstNameLatin: result.latin?.firstName ?? "",
          middleNameLatin: result.latin?.middleName ?? "",
          lastNameLatin: result.latin?.lastName ?? "",
          street: result.addresses?.mailingAddress?.street ?? "",
          city: result.addresses?.mailingAddress?.city ?? "",
          postalCode: result.addresses?.mailingAddress?.postalCode ?? "",
          country: result.addresses?.mailingAddress?.countryCode ?? "",
        };
        setInitialStudentBasicDetails(basicDetails);
        if (onFormValuesChange) {
          onFormValuesChange({
            data: basicDetails,
          });
        }
      })
      .catch((error) => {
        const errorDetails = convertToErrorDetails(error, ErrorCode.CustomerDetailsFetchFailed);
        if (errorDetails?.responseStatusCode != 404) {
          logError(errorDetails);
          setLoadingError(errorDetails);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saveCustomerDetailsAsync = async (values: StudentBasicDetailsForm | FormikValues) => {
    const customerChanges: RequestCustomerChange = {
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      personDetails: {
        gender: values.gender,
        birthCity: values.birthCity,
        birthCountryCode: values.birthCountryCode,
        dateOfBirth: values.dateOfBirth,
        email: values.email,
        nationalityCountryCode: values.nationalityCountryCode,
        passportNumber: values.passportNumber,
        mobilePhone: values.mobilePhone,
        nationalId: values.nationalId,
      },
      latin: {
        firstName: values.firstNameLatin,
        lastName: values.lastNameLatin,
        middleName: values.middleNameLatin,
      },
      addresses: {
        mailingAddress: {
          city: values.city,
          countryCode: values.country,
          street: values.street,
          postalCode: values.postalCode,
        },
      },
    };
    if (values.isInvoiceEmailSelected && customerChanges.personDetails) {
      customerChanges.personDetails.invoiceEmail = values.invoiceEmail || "";
    }
    try {
      await customerServiceApi.updateCustomer(session.accountUuid, customerChanges);
      trackEvent(session.opportunity.id, CustomTrackingEvent.SaveStudentDetailsCheckout);
    } catch (err: unknown) {
      notifications.addErrorNotification({
        title: labels[LabelKey.saveFailed],
        description: labels[LabelKey.saveFailedDescription],
      });
      if (onError) {
        onError();
      }
      throw err;
    }
  };

  useEffect(() => {
    getCustomerInfo();
  }, []);

  const basicDetailValidationSchema = Yup.object({
    firstName: Yup.string().max(40, labels[LabelKey.valueTooLong]).required(labels[LabelKey.validationMessage]),
    lastName: Yup.string().max(80, labels[LabelKey.valueTooLong]).required(labels[LabelKey.validationMessage]),
    email: Yup.string().email().max(80, labels[LabelKey.valueTooLong]).required(labels[LabelKey.validationMessage]),
    isInvoiceEmailSelected: Yup.boolean(),
    invoiceEmail: Yup.string().when("isInvoiceEmailSelected", {
      is: true,
      then: (e) => e.email().max(80).required(labels[LabelKey.validationMessage]),
      otherwise: (e) => e.email().max(80).notRequired(),
    }),
    dateOfBirth: Yup.date().required().max(moment().subtract(5, "years")),
    mobilePhone: Yup.string().max(40, labels[LabelKey.valueTooLong]).required(labels[LabelKey.validationMessage]),
    nationalityCountryCode: Yup.string().required(labels[LabelKey.validationMessage]),
    nationalId:
      featureSettings.isNationalIdEnabled === TRUE
        ? Yup.string().max(40, labels[LabelKey.valueTooLong]).required(labels[LabelKey.validationMessage])
        : Yup.string().max(40, labels[LabelKey.valueTooLong]).notRequired(),
    passportNumber: Yup.string().max(20, labels[LabelKey.valueTooLong]).notRequired(),
    gender: Yup.string().required(),
    birthCountryCode: Yup.string().notRequired(),
    birthCity: Yup.string().max(200, labels[LabelKey.valueTooLong]).notRequired(),
    firstNameLatin:
      featureSettings.isLatinPersonalDetailsEnabled === TRUE
        ? Yup.string().max(30, labels[LabelKey.valueTooLong]).required(labels[LabelKey.validationMessage])
        : Yup.string().max(30, labels[LabelKey.valueTooLong]).notRequired(),
    lastNameLatin:
      featureSettings.isLatinPersonalDetailsEnabled === TRUE
        ? Yup.string().max(30, labels[LabelKey.valueTooLong]).required(labels[LabelKey.validationMessage])
        : Yup.string().max(30, labels[LabelKey.valueTooLong]).notRequired(),
    middleNameLatin:
      featureSettings.isLatinPersonalDetailsEnabled === TRUE && featureSettings.isMiddleNameEnabled
        ? Yup.string().max(30, labels[LabelKey.valueTooLong])
        : Yup.string().max(30, labels[LabelKey.valueTooLong]).notRequired(),
    street: Yup.string().max(255, labels[LabelKey.valueTooLong]).required(labels[LabelKey.validationMessage]),
    city: Yup.string().max(40, labels[LabelKey.valueTooLong]).required(labels[LabelKey.validationMessage]),
    postalCode: Yup.string().max(20, labels[LabelKey.valueTooLong]).required(labels[LabelKey.validationMessage]),
    country: Yup.string().max(80, labels[LabelKey.valueTooLong]).required(labels[LabelKey.validationMessage]),
  });

  const validation = (value: boolean) => {
    return value ? "invalid" : "valid";
  };

  return (
    <ErrorComponentWrapper isLoading={isLoading} error={loadingError}>
      {!isLoading && !loadingError && initialStudentBasicDetails && (
        <Formik
          initialValues={initialStudentBasicDetails}
          enableReinitialize={true}
          onSubmit={saveCustomerDetailsAsync}
          validationSchema={basicDetailValidationSchema}
          validateOnMount={true}
          validateOnBlur={true}
          validateOnChange={true}
        >
          {({ values, dirty, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue, submitForm }) => {
            useEffect(() => {
              if (onFormValuesChange) {
                onFormValuesChange({
                  dirty,
                  disabled: isSubmitting || !isPhoneNumberValid || Object.entries(errors).length > 0 || !!loadingError,
                  data: values,
                  loading: isSubmitting,
                });
              }
            }, [isPhoneNumberValid, isSubmitting, dirty, touched, values, errors, loadingError]);

            useEffect(() => {
              if (complete && onNext) {
                if (dirty) {
                  submitForm()
                    .then(onNext)
                    .catch(() => {
                      /* empty, ignore the error */
                    });
                } else {
                  onNext();
                }
              }
            }, [complete]);

            return (
              <Form>
                <div className="a-gap flex flex-col">
                  <div className="flex flex-col justify-between gap-2">
                    <div className="flex-shrink-0">
                      <Text variant="heading-5-bold">{labels[LabelKey.studentDetails]}</Text>
                    </div>
                    <div className="text-sm">
                      <Text variant="paragraph-body">{labels[LabelKey.studentDetailsFullUpgradeDescription]}</Text>
                    </div>
                  </div>
                  <div>
                    <div className="a-gap-sm flex flex-col">
                      <TextInput
                        data-testid="firstName-input"
                        isRequired={true}
                        validationState={validation(!!(errors.firstName && touched.firstName))}
                        errorMessage={errors.firstName}
                        name={nameofStudentBasicDetailsFrom.firstName}
                        label={labels[LabelKey.firstName]}
                        placeholder={labels[LabelKey.firstName]}
                        type="text"
                        value={values.firstName}
                        onChange={handleChange}
                        isDisabled={isSubmitting}
                        onBlur={handleBlur}
                        touched={touched.firstName}
                        autoComplete="given-name"
                      />
                      {featureSettings.isMiddleNameEnabled === TRUE && (
                        <TextInput
                          data-testid="middleName-input"
                          isRequired={false}
                          errorMessage={errors.middleName}
                          name={nameofStudentBasicDetailsFrom.middleName}
                          validationState={validation(!!(errors.middleName && touched.middleName))}
                          label={labels[LabelKey.middleName]}
                          placeholder={labels[LabelKey.middleName]}
                          type="text"
                          value={values.middleName}
                          onChange={handleChange}
                          isDisabled={isSubmitting}
                          onBlur={handleBlur}
                          touched={touched.middleName}
                          autoComplete="additional-name"
                        />
                      )}
                      <TextInput
                        data-testid="lastName-input"
                        isRequired={true}
                        name={nameofStudentBasicDetailsFrom.lastName}
                        validationState={validation(!!(errors.lastName && touched.lastName))}
                        errorMessage={errors.lastName}
                        label={labels[LabelKey.lastName]}
                        placeholder={labels[LabelKey.lastName]}
                        type="text"
                        value={values.lastName}
                        onChange={handleChange}
                        isDisabled={isSubmitting}
                        onBlur={handleBlur}
                        touched={touched.lastName}
                        autoComplete="family-name"
                      />
                      <TextInput
                        data-testid="email-input"
                        isRequired={true}
                        name={nameofStudentBasicDetailsFrom.email}
                        validationState={validation(!!(errors.email && touched.email))}
                        errorMessage={labels[LabelKey.validationEmailMessage]}
                        label={labels[LabelKey.email]}
                        placeholder={labels[LabelKey.email]}
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        isDisabled={isSubmitting}
                        onBlur={handleBlur}
                        touched={touched.email}
                        autoComplete="email"
                      />
                      <Checkbox
                        data-testid="invoice-email-checkbox"
                        isSelected={values.isInvoiceEmailSelected}
                        onChange={async () =>
                          await setFieldValue(
                            nameofStudentBasicDetailsFrom.isInvoiceEmailSelected,
                            !values.isInvoiceEmailSelected
                          )
                        }
                      >
                        <Text variant="paragraph-body">{labels[LabelKey.addInvoiceEmail]}</Text>
                      </Checkbox>
                      {values.isInvoiceEmailSelected && (
                        <TextInput
                          data-testid="invoice-email-input"
                          isRequired={true}
                          name="invoiceEmail"
                          validationState={validation(!!(errors.invoiceEmail && touched.invoiceEmail))}
                          errorMessage={errors.invoiceEmail}
                          label={labels[LabelKey.invoiceEmail]}
                          placeholder={labels[LabelKey.invoiceEmail]}
                          type="email"
                          value={values.invoiceEmail}
                          onChange={handleChange}
                          isDisabled={isSubmitting}
                          onBlur={handleBlur}
                          touched={touched.invoiceEmail}
                          autoComplete="email"
                        />
                      )}
                      <DatePicker
                        data-testid="dateOfBirth-picker"
                        label={labels[LabelKey.dateOfBirth]}
                        errorMessage={errors.dateOfBirth}
                        validationState={validation(!!(errors.dateOfBirth && touched.dateOfBirth))}
                        name={nameofStudentBasicDetailsFrom.dateOfBirth}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        required={true}
                        value={values.dateOfBirth}
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        touched={touched.dateOfBirth}
                        autoComplete="bday"
                      />
                      <div
                        className={`tel-container a-rounded flex flex-col justify-center border border-neutral-300 
                    ${(errors.mobilePhone && touched.mobilePhone) || (touched.mobilePhone && !isPhoneNumberValid) ? "tel-invalid" : ""} 
                    ${isSubmitting ? "tel-container-disabled" : ""}`}
                      >
                        <IntlTelInput
                          initialValue={values.mobilePhone}
                          onChangeValidity={(value: boolean) => {
                            setPhoneNumberValid(value);
                          }}
                          onChangeNumber={async (value) => {
                            await setFieldValue(nameofStudentBasicDetailsFrom.mobilePhone, value, true);
                          }}
                          usePreciseValidation={true}
                          initOptions={{
                            utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@21.1.1/build/js/utils.js",
                            nationalMode: false,
                            strictMode: false,
                            formatAsYouType: true,
                            customPlaceholder: function () {
                              return labels[LabelKey.phoneNumber];
                            },
                            initialCountry: "auto",
                            geoIpLookup: function (success) {
                              fetch("https://ipapi.co/json")
                                .then(function (res) {
                                  return res.json();
                                })
                                .then(function (data) {
                                  success(data.country_code);
                                })
                                .catch(function () {});
                            },
                          }}
                          inputProps={{
                            onBlur: handleBlur(nameofStudentBasicDetailsFrom.mobilePhone),
                            name: "mobilePhone-input",
                          }}
                        />
                      </div>
                      <Select
                        name="nationality-select"
                        label={labels[LabelKey.nationality]}
                        className="mt-0"
                        defaultSelectedKey={values.nationalityCountryCode}
                        validationState={validation(
                          !!(errors.nationalityCountryCode && touched.nationalityCountryCode)
                        )}
                        errorMessage={errors.nationalityCountryCode}
                        trackingInfo="track-select"
                        isRequired={true}
                        onSelectionChange={async (value) =>
                          await setFieldValue(nameofStudentBasicDetailsFrom.nationalityCountryCode, value, true)
                        }
                        disabled={isSubmitting}
                        touched={touched.nationalityCountryCode}
                        onBlur={handleBlur}
                      >
                        {Object.entries(nationalities).map(([nationalityName, countryCode]) => (
                          <Item key={countryCode} value={countryCode}>
                            {getCountryName(countryCode, language, nationalityName)}
                          </Item>
                        ))}
                      </Select>
                      <TextInput
                        data-testid="passportNumber-input"
                        isRequired={false}
                        name={nameofStudentBasicDetailsFrom.passportNumber}
                        errorMessage={errors.passportNumber}
                        validationState={validation(!!(errors.passportNumber && touched.passportNumber))}
                        label={labels[LabelKey.passportNumber]}
                        placeholder={labels[LabelKey.passportNumber]}
                        type="text"
                        value={values.passportNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isSubmitting}
                        touched={touched.passportNumber}
                      />
                      {featureSettings.isNationalIdEnabled == TRUE && (
                        <TextInput
                          data-testid="nationalId-input"
                          isRequired={featureSettings.isNationalIdEnabled == TRUE}
                          name={nameofStudentBasicDetailsFrom.nationalId}
                          errorMessage={errors.nationalId}
                          validationState={validation(!!(errors.nationalId && touched.nationalId))}
                          label={labels[LabelKey.nationalId]}
                          type="text"
                          value={values.nationalId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isDisabled={isSubmitting}
                          touched={touched.nationalId}
                        />
                      )}
                      {quote.segments
                        .map((s) => s.destinationCode.split("-")[0])
                        .reduce(
                          (acc, destinationCode) =>
                            acc || featureSettings.birthCityEnabledForDestination?.includes(destinationCode),
                          false
                        ) && (
                        <TextInput
                          data-testid="birthCity-input"
                          isRequired={false}
                          validationState={validation(!!(errors.birthCity && touched.birthCity))}
                          errorMessage={errors.birthCity}
                          name={nameofStudentBasicDetailsFrom.birthCity}
                          label={labels[LabelKey.birthCity]}
                          placeholder={labels[LabelKey.birthCity]}
                          type="text"
                          value={values.birthCity}
                          onChange={handleChange}
                          isDisabled={isSubmitting}
                          onBlur={handleBlur}
                          touched={touched.birthCity}
                          autoComplete="address-level2"
                        />
                      )}
                      {featureSettings.isBirthCountryEnabled === TRUE && (
                        <Select
                          name="birthCountry-select"
                          label={labels[LabelKey.birthCountry]}
                          className="mt-0"
                          defaultSelectedKey={values.birthCountryCode}
                          validationState={validation(!!(touched.birthCountryCode && Boolean(errors.birthCountryCode)))}
                          errorMessage={errors.birthCountryCode}
                          trackingInfo="track-select"
                          isRequired={false}
                          onSelectionChange={async (value) =>
                            await setFieldValue(nameofStudentBasicDetailsFrom.birthCountryCode, value, true)
                          }
                          disabled={isSubmitting}
                          touched={touched.birthCountryCode}
                          onBlur={handleBlur}
                          autoComplete="country-name"
                        >
                          {Object.entries(countries).map(([countryCode, countryName]) => (
                            <Item key={countryCode} value={countryCode}>
                              {getCountryName(countryCode, language, countryName)}
                            </Item>
                          ))}
                        </Select>
                      )}
                      <Select
                        name="gender-select"
                        label={labels[LabelKey.gender]}
                        defaultSelectedKey={values.gender}
                        validationState={validation(!!(errors.gender && touched.gender))}
                        errorMessage={errors.gender}
                        className="my-0"
                        trackingInfo="track-select"
                        isRequired={true}
                        onSelectionChange={async (value) =>
                          await setFieldValue(nameofStudentBasicDetailsFrom.gender, value, true)
                        }
                        disabled={isSubmitting}
                        touched={touched.gender}
                        onBlur={handleBlur}
                        autoComplete="sex"
                      >
                        {["Female", "Male", "Other"].map((name) => (
                          <Item key={name} value={name}>
                            {labels["gender_" + name] ?? name}
                          </Item>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>

                {featureSettings.isLatinPersonalDetailsEnabled === TRUE && (
                  <div className="a-gap-sm mt-8 flex flex-col" data-testid="latinPersonalDetails-form">
                    <Text variant="label-lg-bold">{labels[LabelKey.studentLatinDetails]}</Text>
                    <div className="a-gap-sm flex flex-col">
                      <TextInput
                        data-testid="firstNameLatin-input"
                        isRequired={true}
                        validationState={validation(!!(errors.firstNameLatin && touched.firstNameLatin))}
                        errorMessage={errors.firstNameLatin}
                        name={nameofStudentBasicDetailsFrom.firstNameLatin}
                        label={labels[LabelKey.firstNameLatin]}
                        placeholder={labels[LabelKey.firstNameLatin]}
                        type="text"
                        value={values.firstNameLatin}
                        onChange={handleChange}
                        isDisabled={isSubmitting}
                        onBlur={handleBlur}
                        touched={touched.firstNameLatin}
                      />
                      <TextInput
                        data-testid="lastNameLatin-input"
                        isRequired={true}
                        name={nameofStudentBasicDetailsFrom.lastNameLatin}
                        validationState={validation(!!(errors.lastNameLatin && touched.lastNameLatin))}
                        errorMessage={errors.lastNameLatin}
                        label={labels[LabelKey.lastNameLatin]}
                        placeholder={labels[LabelKey.lastNameLatin]}
                        type="text"
                        value={values.lastNameLatin}
                        onChange={handleChange}
                        isDisabled={isSubmitting}
                        onBlur={handleBlur}
                        touched={touched.lastNameLatin}
                      />
                      {featureSettings.isMiddleNameEnabled === TRUE && (
                        <TextInput
                          data-testid="middleNameLatin-input"
                          isRequired={false}
                          validationState={validation(!!(errors.middleNameLatin && touched.middleNameLatin))}
                          errorMessage={errors.middleNameLatin}
                          name={nameofStudentBasicDetailsFrom.middleNameLatin}
                          label={labels[LabelKey.middleNameLatin]}
                          placeholder={labels[LabelKey.middleNameLatin]}
                          type="text"
                          value={values.middleNameLatin}
                          onChange={handleChange}
                          isDisabled={isSubmitting}
                          onBlur={handleBlur}
                          touched={touched.middleNameLatin}
                        />
                      )}
                    </div>
                  </div>
                )}

                <div className="a-gap-sm mt-8 flex flex-col">
                  <Text variant="label-lg-bold">{labels[LabelKey.address]}</Text>
                  <div className="a-gap-sm flex flex-col">
                    <Select
                      data-testid="country-select"
                      name={nameofStudentBasicDetailsFrom.country}
                      label={labels[LabelKey.country]}
                      className="mt-0"
                      defaultSelectedKey={values.country}
                      trackingInfo="track-select"
                      isRequired={true}
                      onSelectionChange={async (value) =>
                        await setFieldValue(nameofStudentBasicDetailsFrom.country, value, true)
                      }
                      disabled={isSubmitting}
                      touched={touched.gender}
                      onBlur={handleBlur}
                      autoComplete="country"
                    >
                      {Object.entries(countries).map(([countryCode, countryName]) => (
                        <Item key={countryCode} value={countryCode}>
                          {getCountryName(countryCode, language, countryName)}
                        </Item>
                      ))}
                    </Select>
                    <TextInput
                      data-testid="address-input"
                      isRequired={true}
                      name={nameofStudentBasicDetailsFrom.street}
                      validationState={validation(!!(errors.street && touched.street))}
                      errorMessage={errors.street}
                      label={labels[LabelKey.address]}
                      placeholder={labels[LabelKey.address]}
                      type="text"
                      value={values.street}
                      onChange={handleChange}
                      isDisabled={isSubmitting}
                      onBlur={handleBlur}
                      touched={touched.street}
                      autoComplete="street-address"
                    />
                    <TextInput
                      data-testid="postalCode-input"
                      isRequired={true}
                      name={nameofStudentBasicDetailsFrom.postalCode}
                      validationState={validation(!!(errors.postalCode && touched.postalCode))}
                      errorMessage={errors.postalCode}
                      label={labels[LabelKey.postalCode]}
                      placeholder={labels[LabelKey.postalCode]}
                      type="text"
                      value={values.postalCode}
                      onChange={handleChange}
                      isDisabled={isSubmitting}
                      onBlur={handleBlur}
                      touched={touched.postalCode}
                      autoComplete="postal-code"
                    />
                    <TextInput
                      data-testid="city-input"
                      isRequired={true}
                      name={nameofStudentBasicDetailsFrom.city}
                      validationState={validation(!!(errors.city && touched.city))}
                      errorMessage={errors.city}
                      label={labels[LabelKey.city]}
                      placeholder={labels[LabelKey.city]}
                      type="text"
                      value={values.city}
                      onChange={handleChange}
                      isDisabled={isSubmitting}
                      onBlur={handleBlur}
                      touched={touched.city}
                      autoComplete="address-level2"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </ErrorComponentWrapper>
  );
};

export default StudentBasicDetails;
