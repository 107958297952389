import React, { useEffect, useState } from "react";
import { Button, Item, Loading, Select, Text, TextInput } from "@ilc-technology/luik";
import { IconButton } from "@ilc-technology/luik";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import InformationBox from "../../InformationBox/InformationBox";
import { CustomTrackingEvent, trackEvent } from "../../../Common/services/Analytics";
import { convertToErrorDetails, getErrorDetailsToDisplay, logError } from "../../../Common/services/ErrorService";
import { ErrorCode, ErrorDetails } from "../../../Common/Types";
import { TRUE } from "../../../Common/Constants";
import { getCountryName } from "../../../Common/services/Countries";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormikHelpers } from "formik/dist/types";
import { CustomerServiceApi } from "../../../apis/customerServiceApi";
import { RequestCustomerChange } from "../../../apis/generatedCustomerServiceApiClient";
import ErrorComponentWrapper from "../../ErrorHandling/ErrorComponentWrapper";

export type BasicStudentDetails = {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  country: string;
  mobilePhone: string;
};

interface StudentDetailsProps {
  onStudentDetailsChange?: (newStudentDetails: BasicStudentDetails) => void;
}

const StudentDetails: React.FC<StudentDetailsProps> = ({ onStudentDetailsChange }) => {
  const { labels, countries, featureSettings } = useDatasources();
  const { session, language } = useSessionContext();
  const customerServiceApi = new CustomerServiceApi();

  const [isFormLoading, setIsFormLoading] = useState(false);
  const [accountUpdateError, setAccountUpdateError] = useState<ErrorDetails | undefined>(undefined);
  const [initialValues, setInitialValues] = useState<BasicStudentDetails | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [loadingError, setLoadingError] = useState<ErrorDetails | undefined>(undefined);

  const getUsersFullDisplayName = (firstName: string, middleName: string, lastName: string) => {
    const nameParts = [firstName, featureSettings.isMiddleNameEnabled === TRUE ? middleName : "", lastName].filter(
      Boolean
    );
    return nameParts.join(" ");
  };

  const getCustomerInfo = () => {
    setIsLoading(true);
    customerServiceApi
      .getCustomer(session.accountUuid)
      .then(async (result) => {
        const studentDetails: BasicStudentDetails = {
          firstName: result.firstName ?? "",
          middleName: result.middleName ?? "",
          lastName: result.lastName ?? "",
          email: result.personDetails?.email ?? "",
          country: result.addresses?.mailingAddress?.countryCode ?? "",
          mobilePhone: result.personDetails?.mobilePhone ?? "",
        };
        setInitialValues(studentDetails);
        setIsEditing(!result.addresses?.mailingAddress?.countryCode);
        if (onStudentDetailsChange) {
          onStudentDetailsChange(studentDetails);
        }
      })
      .catch((error) => {
        const errorDetails = convertToErrorDetails(error, ErrorCode.CustomerDetailsFetchFailed);
        if (errorDetails?.responseStatusCode != 404) {
          logError(errorDetails);
          setLoadingError(errorDetails);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCustomerInfo();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = async (values: BasicStudentDetails, _: FormikHelpers<BasicStudentDetails>) => {
    setIsFormLoading(true);
    setAccountUpdateError(undefined);

    const request: RequestCustomerChange = {
      firstName: values.firstName,
      middleName: values.middleName || undefined,
      lastName: values.lastName,
      addresses: {
        mailingAddress: {
          countryCode: values.country,
        },
      },
    };
    try {
      await customerServiceApi.updateCustomer(session.accountUuid, request);
      setIsEditing(false);
      trackEvent(session.opportunity.id, CustomTrackingEvent.SaveCompleteStudentDetailsPayment);
      setInitialValues(values);
      if (onStudentDetailsChange) {
        onStudentDetailsChange(values);
      }
    } catch (err: unknown) {
      setIsEditing(true);
      setAccountUpdateError(convertToErrorDetails(err, ErrorCode.AccountUpdateRequestFailed));
    } finally {
      setIsFormLoading(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getValidationSchema = (): any => {
    return Yup.object().shape({
      firstName: Yup.string().max(40, labels[LabelKey.valueTooLong]).required(labels[LabelKey.validationMessage]),
      middleName: Yup.string().max(40, labels[LabelKey.valueTooLong]).notRequired(),
      lastName: Yup.string().max(80, labels[LabelKey.valueTooLong]).required(labels[LabelKey.validationMessage]),
      email: Yup.string().max(80, labels[LabelKey.valueTooLong]).notRequired(),
      country: Yup.string().max(80, labels[LabelKey.valueTooLong]).required(labels[LabelKey.validationMessage]),
      mobilePhone: Yup.string().max(40, labels[LabelKey.valueTooLong]).notRequired(),
    });
  };

  return (
    <ErrorComponentWrapper isLoading={isLoading} error={loadingError}>
      {!isLoading && !loadingError && initialValues && (
        <div className="a-gap flex flex-col">
          <div className="absolute right-0 top-0">
            <IconButton
              data-testid="edit-student-details"
              iconName={isEditing ? "close" : "crayon"}
              intent={isEditing ? "secondary-black" : "primary-black"}
              size="xs"
              aria-label="edit-student-details"
              onPress={() => {
                setIsEditing(!isEditing);
              }}
            />
          </div>
          <Text variant="paragraph-body">{labels[LabelKey.studentDetailsDescription]}</Text>

          {!initialValues.country && !isEditing && (
            <div
              onClick={() => {
                setIsEditing(!isEditing);
              }}
            >
              <InformationBox
                intent="warning"
                title={labels[LabelKey.missingDetails]}
                content={labels[LabelKey.missingCountry]}
                iconName={"alert-circle-outlined"}
              />
            </div>
          )}
          {!isEditing && countries && (
            <div className="flex flex-col">
              <Text data-testid="person" variant="paragraph-body">
                {getUsersFullDisplayName(initialValues.firstName, initialValues.middleName, initialValues.lastName)}
              </Text>
              <Text data-testid="person-mobilePhone" variant="paragraph-body">
                {initialValues.mobilePhone}
              </Text>
              <Text data-testid="person-email" variant="paragraph-body">
                {initialValues.email}
              </Text>
              {initialValues.country && (
                <Text data-testid="person-country" variant="paragraph-body">
                  {getCountryName(initialValues.country, language, countries[initialValues.country])}
                </Text>
              )}
            </div>
          )}
          {isEditing && (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={getValidationSchema()}
                validateOnChange={true}
                validateOnMount={true}
                onSubmit={handleSubmit}
              >
                {({
                  setFieldValue,
                  isSubmitting,
                  handleSubmit,
                  dirty,
                  values,
                  handleBlur,
                  handleChange,
                  touched,
                  errors,
                  isValid,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="a-gap-sm flex flex-col">
                      <TextInput
                        data-testid="firstName"
                        aria-label="firstName"
                        isRequired={true}
                        name="firstName"
                        label={labels[LabelKey.firstName]}
                        placeholder={labels[LabelKey.firstName]}
                        type="text"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isFormLoading || isSubmitting}
                        isInvalid={errors.firstName != null}
                        validationState={errors.firstName ? "invalid" : "valid"}
                        errorMessage={errors.firstName}
                        touched={touched.firstName}
                        autoComplete="given-name"
                      />
                      {featureSettings.isMiddleNameEnabled === TRUE && (
                        <TextInput
                          aria-label="middleName"
                          data-testid="middleName"
                          name="middleName"
                          label={labels[LabelKey.middleName]}
                          placeholder={labels[LabelKey.middleName]}
                          type="text"
                          value={values.middleName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isDisabled={isFormLoading}
                          isInvalid={errors.middleName != null}
                          errorMessage={errors.middleName}
                          touched={touched.middleName}
                          autoComplete="additional-name"
                        />
                      )}
                      <TextInput
                        data-testid="lastName"
                        aria-label="lastName"
                        isRequired={true}
                        name="lastName"
                        label={labels[LabelKey.lastName]}
                        placeholder={labels[LabelKey.lastName]}
                        type="text"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isFormLoading || isSubmitting}
                        isInvalid={errors.lastName != null}
                        validationState={errors.lastName ? "invalid" : "valid"}
                        errorMessage={errors.lastName}
                        touched={touched.lastName}
                        autoComplete="family-name"
                      />
                      <div data-testid="country-select">
                        <Select
                          aria-label="country-select"
                          name="country-select"
                          label={labels[LabelKey.country]}
                          className="mb-4 mt-0"
                          defaultSelectedKey={values.country}
                          trackingInfo="track-select"
                          isRequired={true}
                          onSelectionChange={async (value) => await setFieldValue("country", value.toString())}
                          disabled={isFormLoading}
                          onBlur={handleBlur}
                          validationState={errors.country ? "invalid" : "valid"}
                          errorMessage={errors.country}
                          touched={touched.country}
                          autoComplete="country"
                        >
                          {Object.entries(countries).map(([countryCode, countryName]) => (
                            <Item key={countryCode} value={countryCode}>
                              {getCountryName(countryCode, language, countryName)}
                            </Item>
                          ))}
                        </Select>
                      </div>
                    </div>
                    {accountUpdateError && (
                      <InformationBox
                        intent="alert"
                        title={labels[LabelKey.saveFailed]}
                        content={labels[LabelKey.saveFailedDescription]}
                        iconName={"alert-circle-outlined"}
                        additionalInfo={getErrorDetailsToDisplay(accountUpdateError)}
                        testid="account-update-error"
                      />
                    )}
                    <div className="float-right mt-8 flex flex-row items-center gap-6">
                      <Button
                        aria-label="cancel-button"
                        data-testid="cancel-button"
                        intent="secondary-black"
                        size="base"
                        onPress={() => {
                          setIsEditing(!isEditing);
                          setAccountUpdateError(undefined);
                        }}
                        isDisabled={isFormLoading || isSubmitting}
                      >
                        {labels[LabelKey.cancel]}
                      </Button>
                      {isFormLoading || isSubmitting ? (
                        <div className="a-save-button save-details-loading">
                          <Loading className="loading save-details-loading-spinner" size="lg" />
                        </div>
                      ) : (
                        <input
                          aria-label="save-details-button"
                          data-testid="save-details-button"
                          className="a-save-button"
                          type="submit"
                          disabled={isFormLoading || isSubmitting || !dirty || !isValid}
                          value={labels[LabelKey.save]}
                        />
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            </>
          )}
        </div>
      )}
    </ErrorComponentWrapper>
  );
};

export default StudentDetails;
